import React, { useEffect } from 'react';
import './Modal.scss';
import mainLogo from '../assets/logos/main_logo.svg';
import warningIcon from '../assets/icons/warning-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, hideOverlay, showOverlay, sideMenuSelect, zoomCanvas } from '../store/global';
import { selectLayout, selectLayouts } from '../store/layouts';
import { v4 as uuidV4 } from 'uuid';
import {
  createProject,
  execDeleteProject,
  getActiveProject,
  resetProject,
  resetSelectedBanner,
  selectProject,
  setActiveProject,
} from '../store/projects';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

function Modal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.global.modal?.mode);
  const context = useSelector((state) => state.global.modal?.context);
  const project = useSelector(getActiveProject);
  const layout = project ? useSelector((state) => selectLayout(state, project.layoutId)) : null;
  const layouts = useSelector(selectLayouts);
  const selectedBanner = useSelector((state) => state.projects.present.selectedBanner);
  const selectedBannerObject = useSelector((state) => state.projects.present.banner);

  const ctrl = new AbortController();
  useEffect(() => {
    if (!ctrl.signal.aborted) {
      dispatch(showOverlay());
    }
    return () => ctrl.abort();
  }, []);

  const message = () => {
    return {
      0: (
        <>
          Are you sure you would like to <br />
          reset all banners?
        </>
      ),
      1: (
        <>
          Are you sure you would like to <br />
          reset this banner?
        </>
      ),
      2: (
        <>
          Are you sure you would like to
          <br />
          stop editing this version?
        </>
      ),
      3: (
        <>
          Are you sure you would like to
          <br />
          delete the selected template?
        </>
      ),
    }[mode];
  };

  const handleCreateNewDesign = () => {
    const defaultLayout = layouts.find((layout) => layout.id === context.layoutId);

    const template = { ...JSON.parse(JSON.stringify(defaultLayout?.template)) };
    const banners = [...JSON.parse(JSON.stringify(defaultLayout?.banners))];

    const data = {
      id: uuidV4(),
      layoutId: context.layoutId,
      draft: true,
      template,
      banners,
      saved: false,
      createdAt: DateTime.utc().toISO(),
      updatedAt: DateTime.utc().toISO(),
    };
    dispatch(createProject(data));
    dispatch(setActiveProject(data.id));
    handleOnClose();

    return navigate(`/projects/${data.id}`, { replace: true });
  };

  const renderMode = () => {
    switch (mode) {
      case 0:
        dispatch(
          resetProject({
            id: project.id,
            changes: {
              template: { ...JSON.parse(JSON.stringify(layout?.template)) },
              banners: [...JSON.parse(JSON.stringify(layout?.banners))].map((b) => {
                b.oldId = b.id;
                b.id = uuidV4();
                return b;
              }),
              layoutId: layout?.id,
            },
          }),
        );
        break;
      case 1:
        const updatedBanners = [...project.banners];
        const idx = updatedBanners.findIndex((b) => b.id === selectedBanner);
        const defaultState = [...JSON.parse(JSON.stringify(layout?.banners))].find((b) => {
          if (b.id && selectedBanner && selectedBannerObject && selectedBannerObject.oldId === b.id) {
            return b;
          } else if (
            selectedBanner &&
            selectedBannerObject &&
            !selectedBannerObject.oldId &&
            b.width === selectedBannerObject.width &&
            b.height === selectedBannerObject.height
          ) {
            return b;
          }
          return null;
        });

        if (idx >= 0 && defaultState) {
          updatedBanners[idx] = { ...defaultState, oldId: defaultState.id, id: selectedBanner };
          dispatch(resetSelectedBanner({ id: project.id, changes: { banners: [...updatedBanners] } }));
        }
        break;
      case 2:
        return handleCreateNewDesign();
      case 3:
        dispatch(execDeleteProject(context?.projectId));
        break;
    }

    handleOnClose();
  };

  const handleOnClose = () => {
    dispatch(hideModal());
    dispatch(hideOverlay());
  };

  return (
    <div className="modal-popup">
      <section className="modal-container">
        <img className="modal-logo" src={mainLogo} alt="logo" />
        <p>{message()}</p>
        <img className="modal-warning" src={warningIcon} alt="warning" />
      </section>
      <section className="modal-button-container">
        <button type="button" tabIndex="1" onClick={handleOnClose}>
          NO
        </button>
        <button type="button" tabIndex="2" onClick={renderMode}>
          YES
        </button>
      </section>
    </div>
  );
}

export default Modal;
